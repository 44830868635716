import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import IconButton from "@material-ui/core/IconButton/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import {Link as ScrolLink} from "react-scroll/modules";
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function TemporaryDrawer({isUkraine, page}) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (side, open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [side]: open });
    };

    const sideList = side => (
        <div
            className="left-navbar"
            role="presentation"
            style={{minWidth: "300px"}}
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}
        >
            <div>
                <img src="/images/logo-min.svg" onClick={() => this.props.history.push("/")} style={{cursor: "pointer"}}/>
                <span>EUROPREN</span>
            </div>
            {page === "landing" &&
            <Fragment>
                <ScrolLink activeClass="active" to="aboutUs" spy={true} smooth={true} offset={-100} duration={500} onClick={toggleDrawer('left', false)}>
                    {isUkraine ? "Про нас" : "О нас"}
                </ScrolLink>
                <ScrolLink activeClass="active" to="advantages" spy={true} smooth={true} offset={-100} duration={500} onClick={toggleDrawer('left', false)}>
                    {isUkraine ? "Наші переваги" : "Наши преимущества"}
                </ScrolLink>
                {/*<Link to="/"> </Link>*/}
                <Link to="/glues-list/all">
                    {isUkraine ? "Товари і продукція" : "Товары и продукция"}
                </Link>
                <ScrolLink activeClass="active" to="contacts" spy={true} smooth={true} offset={50} duration={500} onClick={toggleDrawer('left', false)}>
                    {isUkraine ? "Контакти" : "Контакты"}
                </ScrolLink>
            </Fragment>
            }
            {page === "list" &&
            <Fragment>
                <Link to={{
                    pathname: `/`,
                    state: 'aboutUs'}}>
                    {isUkraine ? "Про нас" : "О нас"}
                </Link>
                <Link to={{
                    pathname: `/`,
                    state: 'advantages'}} >
                    {isUkraine ? "Наші переваги" : "Наши преимущества"}
                </Link>
                <Link to="/glues-list/all">
                    {isUkraine ? "Товари і продукція" : "Товары и продукция"}
                </Link>
                <Link to={{
                    pathname: `/`,
                    state: 'contacts'}}>
                    {isUkraine ? "Контакти" : "Контакты"}
                </Link>
            </Fragment>
            }
            {page === "card" &&
            <Fragment>
                <Link to={{
                    pathname: `/`,
                    state: 'aboutUs'}}>
                    {isUkraine ? "Про нас" : "О нас"}
                </Link>
                <Link to={{
                    pathname: `/`,
                    state: 'advantages'}} >
                    {isUkraine ? "Наші переваги" : "Наши преимущества"}
                </Link>
                <Link to="/glues-list/all">
                    {isUkraine ? "Товари і продукція" : "Товары и продукция"}
                </Link>
                <Link to={{
                    pathname: `/`,
                    state: 'contacts'}}>
                    {isUkraine ? "Контакти" : "Контакты"}
                </Link>
            </Fragment>
            }

        </div>
    );


    return (
        <div className="drawer">
            <IconButton onClick={toggleDrawer('left', true)}>
                <MenuIcon />
            </IconButton>
            <Drawer open={state.left} onClose={toggleDrawer('left', false)}>
                {sideList('left')}
            </Drawer>
        </div>
    );
}
