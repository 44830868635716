import React, {Component} from 'react';
// import './ImageCropper.css';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {dataURItoBlob} from "../../static/constant";
// import {Modal, Button, ModalBody, ModalHeader, ModalFooter} from "reactstrap";
// import {dataURItoBlob} from './../../../static/utils'

class ImageCropper extends Component {
    constructor(props) {
        super(props);
    }

    _crop() {
        const dataUrl = this.refs.cropper.getCroppedCanvas().toDataURL();
    }

    saveImage = () => {
        const {toggle, onDrop} = this.props;
        const blob = dataURItoBlob(this.refs.cropper.getCroppedCanvas().toDataURL());
        onDrop(blob);
        // toggle();
    };

    render() {
        const {modal, toggle, currentImage} = this.props;
        return (
            <div style={{ margin: "0 auto", padding: "40px 0"}}>
                <Cropper
                    ref='cropper'
                    src={currentImage}
                    style={{height: 400, width: '500px', margin: "0 auto"}}
                    // Cropper.js options
                    aspectRatio={1 / 1}
                    guides={false}
                    crop={this._crop.bind(this)}/>
                <button  onClick={this.saveImage}
                        style={{
                            background: "#F0D64B",
                            borderStyle: "none",
                            marginTop: "30px",
                            padding: "12px 30px",
                            fontSize: "22px",
                            borderRadius: "10px",
                        }}
                > Save</button>
            </div>

        );
    }
}

export default ImageCropper
