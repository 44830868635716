import React, {Fragment} from 'react';
import {getAllGlues, getGlueByUid} from "../../firebase/api/glues";
import {Link, withRouter} from 'react-router-dom'

import './glue-card.css'
import GridBackground from "../../components/GridBackground";
import SimpleModal from "../../components/Modal";
import {Element} from "react-scroll/modules";
import {emailTemplate} from "../../templates/emailTemplate";
import {sendEmail} from "../../firebase/api/mail";
import {connect} from 'react-redux'
import {changeLanguage} from "../../redux/actions";
import TemporaryDrawer from "../../components/Drawer";


class Glue extends React.Component{
    state = {
        glue: null,
        contactUs: false,
        name: '',
        phone: '',
        mail: '',

    };

    componentDidMount () {
        const { match: { params }, location } = this.props;
        location.state
            ? this.setState({glue: location.state})
            : getGlueByUid(params.id).then(res => {
                res
                    ? this.setState({glue: res})
                    : this.setState({error: "Такого клею на жаль вже нема!"})
            }).catch(err => {
                console.log(err, "some eror");
            })
    }
    handleChange = (name) => (e) => {
        this.setState({[name]: e.target.value})
    };
    changeLanguage = () => {
        const {isUkraine, changeLanguage} = this.props;
        isUkraine ? localStorage.setItem('ru', "true") : localStorage.removeItem('ru');
        changeLanguage()
    };

    sendUserData = (e) => {
        e.preventDefault();
        const {name, phone, mail, glue} = this.state;

        const dto = {
            subject: `На карточці товару`,
            sendTo: "dimavoytynskyy77@gmail.com",
            body: emailTemplate(`
                <p style="margin-bottom:5px">Name: ${name}</p>
                <p style="margin-bottom:5px">Phone: ${phone}</p>
                <p style="margin-bottom:5px">Mail: ${mail} </p>
                <p style="margin-bottom:5px">Назва клею: ${glue.title} </p>
                `),
            link: 'links',
        };

        sendEmail(dto);
        this.setState({name: '', phone: "", mail: "", contactUs: false})

    };

    render() {
        const {glue, contactUs, mail, phone, name, error} = this.state;
        const {isUkraine, changeLanguage} = this.props;
        console.log(glue && glue.technicalCharacters);
        return (
            <div style={{position: "relative", overflow: "hidden"}}>
                <GridBackground/>

                <div className="gradient-wrapper">
                    <header className="header">
                        <div className="header-wrapper">
                            <span className="fixed-logo">EUROPREN</span>
                            <div className="header-log">
                                <img src="/images/logo-min.svg" onClick={() => this.props.history.push("/")} style={{cursor: "pointer"}}/>
                                <TemporaryDrawer isUkraine={isUkraine} page={"card"}/>
                            </div>
                            <nav className="naw">
                                <Link to={{
                                    pathname: `/`,
                                    state: 'aboutUs'}}>
                                    {isUkraine ? "Про нас" : "О нас"}
                                </Link>
                                <Link to={{
                                    pathname: `/`,
                                    state: 'advantages'}} >
                                    {isUkraine ? "Наші переваги" : "Наши преимущества"}
                                </Link>
                                <Link to="/glues-list/all">
                                    {isUkraine ? "Товари і продукція" : "Товары и продукция"}
                                </Link>
                                <Link to={{
                                    pathname: `/`,
                                    state: 'contacts'}}>
                                    {isUkraine ? "Контакти" : "Контакты"}
                                </Link>
                            </nav>
                            <div className="header-info">
                                <button onClick={this.changeLanguage}>{isUkraine ? "UA" : "RU"}</button>
                                <div onClick={() => this.setState({showPhone: !this.state.showPhone})}>
                                    <span>+380676746215 <br/><span className="phone-type">директор</span></span>
                                    <button className="show-phones"/>
                                </div>

                                {this.state.showPhone &&
                                <p className="header-info-wrapper">
                                    <span>+380674927834 <br/><span className="phone-type">менеджер</span></span>
                                    <span>+380631388408 <br/><span className="phone-type">менеджер</span></span>
                                </p>
                                }
                            </div>
                        </div>
                    </header>
                    <div className="main-container" >
                        {error ? error :
                        <Fragment>
                            { glue &&
                            <div>
                                <div className="glue-card-title">
                                    <h2>{glue.title}</h2>
                                    <h3>{isUkraine ? glue.description : glue.descriptionRu}</h3>
                                </div>
                                <div className="glue-card">
                                    <div className="glue-card-image">
                                        <img src={glue.image.downloadMediaUrl} alt=""/>
                                    </div>
                                    <div className="glue-card-info">

                                        <div className="glue-card-info-characters">
                                            <h3>
                                                {isUkraine ? "Загальна характеристика:" : "Общая характеристика:"}
                                            </h3>
                                            <div className="scroll">
                                                {isUkraine
                                                    ? glue.characters.split("%%").map((element, id) => <p key={id}>{element}</p>)
                                                    : glue.charactersRu.split("%%").map((element, id) => <p key={id}>{element}</p>)
                                                }
                                            </div>
                                        </div>
                                        {/*<div>*/}
                                        <button className="glue-card-info-button gold-hover" onClick={() => this.setState({contactUs: true})}>
                                            {isUkraine ? "Зв’язок" : "Связаться"}
                                        </button>
                                        {/*</div>*/}
                                    </div>
                                </div>


                            </div>
                            }
                            {glue &&
                            <div className="glue-card-bottom-wrapper">
                                <div className="glue-card-appointment">
                                    <h3>
                                        {isUkraine ? "ПРИЗНАЧЕННЯ:" : "НАЗНАЧЕНИЕ:"}
                                    </h3>
                                    <div className="scroll">
                                        {isUkraine
                                            ? glue.appointment.split("%%").map((element, id) => <p key={id}>{element}</p>)
                                            : glue.appointmentRu.split("%%").map((element, id) => <p key={id}>{element}</p>)
                                        }
                                    </div>
                                </div>
                                <div className="glue-card-technical-characters">
                                    <h2>
                                        {isUkraine ? "ТЕХНІЧНА ХАРАКТЕРИСТИКА" : "ТЕХНИЧЕСКАЯ ХАРАКТЕРИСТИКА"}
                                    </h2>
                                    <table className="glue-card-technical-characters-table">
                                        <tbody>
                                        <tr>
                                            <td>
                                                {isUkraine ? "Основа" : "Основа"}
                                            </td>
                                            <td>{glue.technicalCharacters.basic}</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                {isUkraine ? "Колір" : "Цвет"}
                                            </td>
                                            <td>{glue.technicalCharacters.color}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {isUkraine ? "Запах" : "Запах"}
                                            </td>
                                            <td>{glue.technicalCharacters.smell}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {isUkraine ? "Вміст сухого залишку" : "Содержание сухого остатка"}
                                            </td>
                                            <td>{glue.technicalCharacters.remainder}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {isUkraine ? "Температура роботи" : "Температура работы"}
                                            </td>
                                            <td>{glue.technicalCharacters.temperature}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {isUkraine ? "Відкритий час" : "Открытое время"}
                                            </td>
                                            <td>{glue.technicalCharacters.time}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {isUkraine ? "В'язкість" : "Вязкость"}
                                            </td>
                                            <td>{glue.technicalCharacters.constancy}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {isUkraine ? "Нанесення клея" : "Нанесение клея"}
                                            </td>
                                            <td>{glue.technicalCharacters.area}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {isUkraine ? "Температура виносливості Шва" : "Температура выносливости Шва"}
                                            </td>
                                            <td>{glue.technicalCharacters.suture}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {isUkraine ? "Діаметер Сопла" : "Диаметр Сопла"}
                                            </td>
                                            <td>{glue.technicalCharacters.diameter}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {isUkraine ? "Температура зберігання" : "Температура хранения"}
                                            </td>
                                            <td>{glue.technicalCharacters.tempSave}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {isUkraine ? "Продуктивність" : "Производительность"}
                                            </td>
                                            <td>{glue.technicalCharacters.productivity}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                            }
                        </Fragment>
                        }


                    </div>

                </div>

                <section className="footer-main">
                    <footer className="footer">
                        <nav className="naw">
                            <Link to={{
                                pathname: `/`,
                                state: 'aboutUs'}}>
                                {isUkraine ? "Про нас" : "О нас"}
                            </Link>
                            <Link to={{
                                pathname: `/`,
                                state: 'advantages'}} >
                                {isUkraine ? "Наші переваги" : "Наши преимущества"}
                            </Link>
                            <Link to="/glues-list/all">
                                {isUkraine ? "Товари і продукція" : "Товары и продукция"}
                            </Link>
                            <Link to={{
                                pathname: `/`,
                                state: 'contacts'}}>
                                {isUkraine ? "Контакти" : "Контакты"}
                            </Link>
                        </nav>
                        <div className="header-info">
                            <button onClick={this.changeLanguage}>{isUkraine ? "UA" : "RU"}</button>
                            <div>
                                <span>+380676746215 <br/> директор</span>
                            </div>
                        </div>
                    </footer>

                </section>
                <SimpleModal open={contactUs} toggle={() => this.setState({contactUs: false})}>
                    <div  className="glue-card-contact-us-modal">
                        <button className="close-contact-us-modal" onClick={() => this.setState({contactUs: false})}>X</button>
                        <div className="contacts" style={{padding: 0}}>
                            <div className="contacts-wrapper">
                                <div className="contacts-backConnect">
                                    <div className="border-wrapper"/>
                                    <Element name="contacts"/>
                                    <form  className="contacts-usersDto" onSubmit={this.sendUserData}>
                                        <input type="text" placeholder={isUkraine ? "Ваше Ім’я" : "Ваше имя"} onChange={this.handleChange('name')} value={name} required/>
                                        <input type="number" placeholder={isUkraine ? "Номер телефону" : "Телефонный номер"} onChange={this.handleChange('phone')} value={phone} required/>
                                        <input type="email" placeholder={isUkraine ? "Ваша пошта" : "Ваша почта"} onChange={this.handleChange('mail')} value={mail} required/>
                                        <input type="submit" style={{display: "none"}} id="submit-button"/>
                                    </form>
                                </div>
                                <div className="contacts-info">
                                    <label htmlFor="submit-button" className="gold-hover">
                                        {isUkraine ? "Відправити" : "Отправить"}
                                    </label>
                                    <div className="contacts-info-wrapper">
                                        <p>
                                            {isUkraine ? "Понеділок- П‘ятниця" : "Понедельник-пятница"}
                                            <br/> 9:00 - 18:00
                                        </p>
                                        <p>+380 67 67 462 15 <br/><span className="smol-text">директор</span><br/> +380 67 49 278 34 <br/><span className="smol-text">менеджер з продаж</span><br/> +380 63 138 84 08 <br/><span className="smol-text">менеджер з продаж</span></p>
                                        <p>europren@ukr.net</p>
                                    </div>
                                </div>
                                <div className="contacts-images">
                                    <img src="/images/contact-1.png" alt=""/>
                                    <img src="/images/contact-2.png" alt=""/>
                                </div>
                            </div>
                        </div>

                    </div>

                </SimpleModal>
            </div>
        )
    }
}

const mapStateToProps = ({gluesReducer: {isUkraine}}) => {
    return {isUkraine};
};

export default connect(mapStateToProps, {changeLanguage})(withRouter(Glue))
