export const Types = {
    GET_ALL_GLUES_SUCCESS: "GET_ALL_GLUES_SUCCESS",
    UPDATE_GLUES_TREE: "UPDATE_GLUES_TREE",
    SEARCH_GLUES_BY_TITLE: "SEARCH_GLUES_BY_TITLE",
    CHANGE_LANGUAGE: "CHANGE_LANGUAGE",
};


 export const getAllGluesSuccess = (glues, activeCategory, gluesTree)=> ({
    type: Types.GET_ALL_GLUES_SUCCESS,
    payload: {glues, activeCategory, gluesTree}
});

export const UpdateGluesTree = (gluesTree)=> ({
    type: Types.UPDATE_GLUES_TREE,
    payload: gluesTree
});

export const searchGluesByTitle = (glues)=> ({
    type: Types.SEARCH_GLUES_BY_TITLE,
    payload: glues
});

export const changeLanguage = ()=> ({
    type: Types.CHANGE_LANGUAGE,
});

