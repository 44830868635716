import React from "react"


import { Link as ScrolLink, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Typing from 'react-typing-animation';
import  "./landing.css"

import {connect} from 'react-redux'

import {Link}  from "react-router-dom"
import {sendEmail} from "../../firebase/api/mail";
import {emailTemplate} from "../../templates/emailTemplate";
import GridBackground from "../../components/GridBackground";
import TemporaryDrawer from "../../components/Drawer";
import {changeLanguage} from "../../redux/actions";



 class Landing extends React.Component{

    state = {
        name: '',
        phone: '',
        mail: ''
    };

    componentDidMount () {
        const {location: {state}} = this.props;

        if (state) this.scrollTo(state);
    };


    handleChange = (name) => (e) => {
        this.setState({[name]: e.target.value})
    };

    sendUserData = (e) => {
        e.preventDefault();
        const {name, phone, mail} = this.state;

        const dto = {
            subject: `На головній сторінці`,
            sendTo: "dimavoytynskyy77@gmail.com",
            body: emailTemplate(`
                <p style="margin-bottom:5px">Name: ${name}</p>
                <p style="margin-bottom:5px">Phone: ${phone}</p>
                <p style="margin-bottom:5px">Mail: ${mail} </p>
                `),
            link: 'links',
        };
        sendEmail(dto);

        this.setState({name: '', phone: "", mail: ""})


    };

    redirectToCategory = (category) => {
        this.props.history.push(`/glues-list/${category}`)
    };

    scrollTo = (to) => {
        scroller.scrollTo(to, {duration: 500,
            delay: 100,
            // offset: -100,
            smooth: true});
    };
    changeLanguage = () => {
        const {isUkraine, changeLanguage} = this.props;
        isUkraine ? localStorage.setItem('ru', "true") : localStorage.removeItem('ru');
         changeLanguage()
    };
    render() {
        const {name, phone, mail} = this.state;
        const {isUkraine, changeLanguage} = this.props;
        return (
            <div style={{position: "relative", overflow: "hidden"}} className="landing">
                <GridBackground/>
                <div className="gradient-wrapper">
                    <header className="header">
                        <div className="header-wrapper">
                            <span className="fixed-logo">EUROPREN</span>
                            <div className="header-log">
                                <img src="/images/logo-min.svg"/>
                                <TemporaryDrawer isUkraine={isUkraine} page={"landing"}/>
                            </div>

                            <nav className="naw">

                                <ScrolLink activeClass="active" to="aboutUs" spy={true} smooth={true} offset={-100} duration={500}>
                                    {isUkraine ? "Про нас" : "О нас"}
                                </ScrolLink>
                                <ScrolLink activeClass="active" to="advantages" spy={true} smooth={true} offset={-100} duration={500}>
                                    {isUkraine ? "Наші переваги" : "Наши преимущества"}
                                </ScrolLink>
                                <Link to="/glues-list/all">
                                    {isUkraine ? "Товари і продукція" : "Товары и продукция"}
                                </Link>
                                <ScrolLink activeClass="active" to="contacts" spy={true} smooth={true} offset={50} duration={500}>
                                    {isUkraine ? "Контакти" : "Контакты"}
                                </ScrolLink>
                            </nav>
                            <div className="header-info ">
                                <button onClick={this.changeLanguage}>{isUkraine ? "UA" : "RU"}</button>
                                <div onClick={() => this.setState({showPhone: !this.state.showPhone})}>
                                    <span>+380676746215 <br/><span className="phone-type">директор</span></span>
                                    <button className="show-phones"/>
                                </div>

                                {this.state.showPhone &&
                                    <p className="header-info-wrapper">

                                        <span>+380674927834 <br/><span className="phone-type">менеджер</span></span>
                                        <span>+380631388408 <br/><span className="phone-type">менеджер</span></span>
                                    </p>
                                }
                            </div>
                        </div>
                    </header>
                    <div className="main-container">
                        <div className="banner">
                            <div className="banner-title">
                                <img src="/images/logo-min.svg" />
                                <div className="banner-title-wrapper">

                                    <Typing speed={100} >
                                        <h1>EUROPREN</h1>
                                    </Typing>
                                    <p>
                                        {isUkraine ? "Українська Торгова Група" : "Украинская Торговая Группа"}
                                    </p>
                                </div>
                            </div>
                            <div className="banner-photo">

                                <img src="/images/3.png" alt=""/>
                                <img src="/images/1.png" alt=""/>
                                <img src="/images/5-1.png" alt=""/>

                                <img src="/images/4-banner.png" alt=""/>
                                <img src="/images/4.png" alt=""/>
                            </div>
                            <div className="banner-details">
                                <button onClick={() => this.scrollTo("aboutUs")} className="gold-hover">
                                    {isUkraine ? "Детальніше" : "Подробнее"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <Element name="aboutUs"/>
                    <div className="main-container">

                        <div className="about-us">
                            <div className="about-us-wrapper">
                                <h2>
                                    {isUkraine ? "Про нас" : "О нас"}
                                </h2>
                                <div>
                                    {isUkraine
                                        ? "ЛАСКАВО ПРОСИМО НА САЙТ КОМПАНІЇ «ЄВРОПРЕН»– ОДНОГО З ЛІДЕРІВ ТА АКТИВНОГО УЧАСНИКА КЛЕЙОВОГО РИНКУ."
                                        : "ДОБРО ПОЖАЛОВАТЬ НА САЙТ КОМПАНИИ «ЕВРОПРЕН» - ОДНОГО ИЗ ЛИДЕРОВ И АКТИВНОГО УЧАСТНИКА  КЛЕЕВОГО РЫНКА."
                                    }
                                </div>
                            </div>
                            <div className="about-us-content">
                                <p>
                                    {isUkraine
                                        ? " В 2018 році, за результатами багаторічного досвіду, постійного розвитку та вдосконалення, детального аналізу переваг та недоліків різних виробників, приватне підприємство було реорганізовано у ТзОВ \"УКРАЇНСЬКА ТОРГОВА ГРУПА «ЄВРОПРЕН», яка на сьогоднішній день є провідним постачальником широкого асортименту клеїв та допоміжної продукції на ринку України. Ми працюємо вивлючно з високоякісним європейським продуктом та представляємо компанію \"BOCHEM\" (Польща), яка з 1991 року і до сьогодні визнається лідером у сфері виробництва промислових клеїв та інших товарів для різних галузей промисловості."
                                        : "В 2018 году, по результатам многолетнего опыта, постоянного развития и совершенствования, детального анализа преимуществ и недостатков различных производителей, частное предприятие было реорганизовано в ООО \"УКРАИНСКАЯ ТОРГОВАЯ ГРУППА« ЕВРОПРЕН », которая на сегодняшний день является ведущим поставщиком широкого ассортимента клеев и вспомогательной продукции на рынке Украины. Мы работаем вивлючно с высококачественным европейским продуктом и представляем компанию \"BOCHEM\" (Польша), которая с 1991 года и до сих пор признается лидером в сфере производства промышленного клея и других товаров для различных отраслей промышленности."
                                    }
                                </p>
                            </div>
                            <div className="about-us-footer">
                                <img src="/images/about-us.png" alt=""/>
                                <button onClick={() => this.scrollTo("category")} className="gold-hover">
                                    {isUkraine ? "Переглянути товар" : "Посмотреть товар"}
                                </button>
                            </div>
                        </div>

                        <div className="advantages">
                            <Element name="advantages"/>
                            <h2 className="advantages-title">
                                {isUkraine ? "Наші переваги" : "Наши преимущества"}
                            </h2>
                            <div className="advantages-wrapper">
                                <div className="advantages-item">
                                    <p>
                                        {isUkraine ? "НАЙНИЖЧІ ЦІНИ" : "НИЗКИЕ ЦЕНЫ"}
                                         <br/>
                                        {isUkraine ? "НА РИНКУ" : "НА РЫНКЕ"}
                                    </p>
                                    <div className="advantages-image">
                                        <div className="advantages-image-layout"/>
                                        <img src="/images/advantage-1.png" alt=""/>
                                    </div>

                                </div>
                                <div className="advantages-item">
                                    <p>
                                        {isUkraine ? "БЕЗКОШТОВНЕ ОТРИМАННЯ ВЗІРЦІВ" : "БЕСПЛАТНОЕ ПОЛУЧЕНИЕ образца"}
                                    </p>
                                    <div className="advantages-image">
                                        <div className="advantages-image-layout"/>
                                        <img src="/images/advantage-2.png" alt=""/>
                                    </div>
                                </div>
                                <div className="advantages-item">
                                    <p>
                                        {isUkraine ? "ТЕХНІЧНА ПІДТРИМКА НАШИХ ФАХІВЦІВ" : "ТЕХНИЧЕСКАЯ ПОДДЕРЖКА НАШИХ СПЕЦИАЛИСТОВ"}
                                    </p>
                                    <div className="advantages-image">
                                        <div className="advantages-image-layout"/>
                                        <img src="/images/advantage-3.png" alt=""/>
                                    </div>                                </div>
                                <div className="advantages-item">
                                    <p>
                                        {isUkraine ? "ВИСОКА ТА" : "ВЫСОКАЯ И"}
                                        <br/>
                                        {isUkraine ? "СТАБІЛЬНА ЯКІСТЬ" : "Стабильное качество"}
                                    </p>
                                    <div className="advantages-image">
                                        <div className="advantages-image-layout"/>
                                        <img src="/images/advantage-4.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="products">
                            <Element name="category"/>
                            <div className="products-title">
                                <h2>
                                    {isUkraine ? "Товари і продукція" : "Товары и продукция"}
                                </h2>
                                <Link to="glues-list/shoes">
                                    {isUkraine ? "Переглянути всі товари" : "Посмотреть все товары"}
                                </Link>
                            </div>
                            <div className="products-wrapper">
                                <div className="products-item" onClick={this.redirectToCategory.bind(this, "shoes")}>
                                    <p>
                                        {isUkraine ? "КЛЕЇ ДЛЯ ВЗУТТЄВОЇ ПРОМИСЛОВОСТІ" : "КЛЕИ для обувной промышленности"}
                                    </p>
                                    <img src="/images/products-1.png" alt=""/>
                                </div>
                                <div className="products-item" onClick={this.redirectToCategory.bind(this, "furniture")}>
                                    <p>
                                        {isUkraine ? "КЛЕЇ ДЛЯ ВИРОБНИЦТВА М’ЯГКИХ МЕБЛІВ ТА МАТРАЦІВ" : "КЛЕИ ДЛЯ ПРОИЗВОДСТВА мягкой мебели и матрасов"}
                                    </p>
                                    <img src="/images/products-2.png" alt=""/>
                                </div>
                                <div className="products-item" onClick={this.redirectToCategory.bind(this, "tree")}>
                                    <p>
                                        {isUkraine ? "КЛЕЇ ДЛЯ ДЕРЕВА" : "КЛЕИ ДЛЯ ДЕРЕВА"}
                                    </p>
                                    <img src="/images/products-3.png" alt=""/>
                                </div>
                                <div className="products-item" onClick={this.redirectToCategory.bind(this, "build")}>
                                    <p>
                                        {isUkraine ? "КЛЕЇ ТА ПРОДУКЦІЯ ДЛЯ БУДІВНИЦТВА" : "КЛЕИ И ПРОДУКЦИЯ ДЛЯ СТРОИТЕЛЬСТВА"}
                                    </p>
                                    <img src="/images/products-4.png" alt=""/>
                                </div>
                                <div className="products-item" onClick={this.redirectToCategory.bind(this, "automobile")}>
                                    <p>
                                        {isUkraine ? "КЛЕЇ ДЛЯ РЕЗИНИ ТА АВТОМОБІЛЬНОЇ ПРОМИСЛОВОСТІ" : "КЛЕЇ ДЛЯ РЕЗИНИ ТА АВТОМОБІЛЬНОЇ ПРОМИСЛОВОСТІ"}
                                    </p>
                                    <img src="/images/products-5.png" alt=""/>
                                </div>
                                <div className="products-item" onClick={this.redirectToCategory.bind(this, "paper")}>
                                    <p>
                                        {isUkraine ? "КЛЕЇ ДЛЯ ЕТИКЕТОК ТА ПАПЕРУ" : "КЛЕИ ДЛЯ этикеток И БУМАГИ"}
                                    </p>
                                    <img src="/images/products-6.png" alt=""/>
                                </div>
                                <div className="products-item" onClick={this.redirectToCategory.bind(this, "others")}>
                                    <p>
                                        {isUkraine ? "ДОПОМІЖНІ ТОВАРИ" : "вспомогательные ТОВАРЫ"}
                                    </p>
                                    <img src="/images/products-7.png" alt=""/>
                                </div>
                                <div className="products-item products-item__custom">
                                    <div className="products-item__custom-wrapper">
                                        <img src="/images/products-9.png" alt=""/>
                                        <img src="/images/products-8.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                <section className="bottom-content">
                    <div className="main-container">
                        <div className="contacts">
                            <h2 className="contacts-title">
                                {isUkraine ? "Контакти" : "Контакты"}
                            </h2>
                            <form className="contacts-wrapper" onSubmit={this.sendUserData}>
                                <div className="contacts-backConnect">
                                    <div className="border-wrapper"/>
                                    <Element name="contacts"/>
                                    <form  className="contacts-usersDto" onSubmit={this.sendUserData}>
                                        <input type="text" placeholder={isUkraine ? "Ваше Ім’я" : "Ваше имя"} onChange={this.handleChange('name')} value={name} required/>
                                        <input type="number" placeholder={isUkraine ? "Номер телефону" : "Телефонный номер"} onChange={this.handleChange('phone')} value={phone} required/>
                                        <input type="email" placeholder={isUkraine ? "Ваша пошта" : "Ваша почта"} onChange={this.handleChange('mail')} value={mail} required/>
                                        <input type="submit" style={{display: "none"}} id="submit-button"/>
                                    </form>
                                </div>
                                <div className="contacts-info">
                                    <label htmlFor="submit-button"   className="gold-hover">
                                        {isUkraine ? "Відправити" : "Отправить"}
                                    </label>
                                    <div className="contacts-info-wrapper">
                                        <p>
                                            {isUkraine ? "Понеділок- П‘ятниця" : "Понедельник-пятница"}
                                            <br/> 9:00 - 18:00</p>
                                        <p>+380 67 67 462 15 <br/><span className="smol-text">директор</span><br/> +380 67 49 278 34 <br/><span className="smol-text">менеджер з продаж</span><br/> +380 63 138 84 08 <br/><span className="smol-text">менеджер з продаж</span></p>
                                        <p>europren@ukr.net</p>
                                    </div>
                                </div>
                                <div className="contacts-images">
                                    <img src="/images/contact-1.png" alt=""/>
                                    <img src="/images/contact-2.png" alt=""/>
                                </div>
                            </form>
                        </div>
                    </div>


                </section>
                <section className="footer-main">
                    <footer className="footer">
                        <nav className="naw">

                            <ScrolLink activeClass="active" to="aboutUs" spy={true} smooth={true} offset={-100} duration={500}>
                                {isUkraine ? "Про нас" : "О нас"}
                            </ScrolLink>
                            <ScrolLink activeClass="active" to="advantages" spy={true} smooth={true} offset={-100} duration={500}>
                                {isUkraine ? "Наші переваги" : "Наши преимущества"}
                            </ScrolLink>
                            <Link to="/glues-list/all">
                                {isUkraine ? "Товари і продукція" : "Товары и продукция"}
                            </Link>
                            <ScrolLink activeClass="active" to="contacts" spy={true} smooth={true} offset={50} duration={500}>
                                {isUkraine ? "Контакти" : "Контакты"}
                            </ScrolLink>
                        </nav>
                        <div className="header-info">
                            <button onClick={this.changeLanguage}>{isUkraine ? "UA" : "RU"}</button>
                            <div onClick={() => this.setState({showPhone: !this.state.showPhone})}>
                                <span>+380676746215 <br/> директор</span>
                            </div>
                        </div>
                    </footer>
                </section>
            </div>
        )
    }

};

const mapStateToProps = ({gluesReducer: {isUkraine}}) => {
    return {isUkraine};
};

export default connect(mapStateToProps, {changeLanguage})(Landing)



