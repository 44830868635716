import app from "firebase/app";

export const getGluesCollection = () => app.firestore().collection("glues");

export const updateGlue = (dto) => {
    return new Promise( function (resolve, reject) {
        console.log(dto);
        // const docRef =  app.firestore().collection("glues").doc(dto.uid).update(dto);
        const docRef =  getGluesCollection().doc(dto.id);
        docRef.update({...dto});
        if (docRef && docRef) {
            resolve(docRef.id)
        } else {
            reject("Object not created");
        }
    })
};


export const createGlue = async (dto) => {
    const docRef = await app.firestore().collection("glues").doc();
    docRef.set({...dto, id: docRef.id});
    if (docRef && docRef.id) {
        return(docRef.id)
    }
};

export const deleteGlue = async (id) => {
    const docRef = await app.firestore().collection("glues").doc(id);
    docRef.delete();
    if (docRef && docRef.id) {
        return(docRef.id)
    }
};

export const getGlueByUid = (id) => {
    let docRef = getGluesCollection().doc(id);
    return docRef.get().then(function (doc) {
        if (doc.exists) {
            return doc.data();
        } else {
            console.log("No such document!");
        }
    }).catch(function (error) {
        console.log("Error getting document:", error);
    });
};
//
export const getAllGlues = async (type = "all") => {
    // let docRef =  type === "all" || !type ?
    let docRef = await app.firestore().collection("glues").get();
        // await app.firestore().collection("glues").where("glueType", "==", type).get();

    let dataRes = [];
    docRef.forEach(function(doc) {
            dataRes.push(doc.data())
        });

    return dataRes
};

export const getGluesByTitle = async (type = "all") => {
    let docRef =  type === "all" || !type ?
        await app.firestore().collection("glues").get() :
        await app.firestore().collection("glues").where("title", ">=", type).get();

    let dataRes = [];
    docRef.forEach(function(doc) {
        dataRes.push(doc.data())
    });

    return dataRes
};
