const populateMetadata = media => {
    return {
        contentType: media.type,
        customMetadata: {
            'name': media.name
        }
    };
};

const randomKey = (length) => {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
};

const dataURItoBlob = (dataURI) => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
};

const gluesDto = {
    shoes: {
        title: "КЛЕЇ ДЛЯ ВЗУТТЄВОЇ ПРОМИСЛОВОСТІ",
        ruTitle: "КЛЕИ для обувной промышленности",
        show: false,
        isLoaded: false,
        count: 0,
        category: {
            organic: {
                count: 0,
                title: "НА ОСНОВІ ОРГАНІЧНИХ РОЗЧИННИКІВ",
                ruTitle: "На основе органических растворителей",
                elements: [],
                show: false
            },
            soluble: {count: 0,
                title: "РОЗЧИННІ КЛЕЇ без толуола",
                ruTitle: "РАСТВОРИМЫЕ КЛЕИ без толуола",
                elements: [],
                show: false
            },
            disperse: {count: 0,
                title: "ДИСПЕРСНІ КЛЕЇ",
                ruTitle: "дисперсны КЛЕИ",
                elements: [],
                show: false
            },
        }
    },
    furniture: {
        count: 0,
        title: "КЛЕЇ ДЛЯ ВИРОБНИЦТВА м‘ягких МЕБЛІВ ТА МАТРАЦІВ",
        ruTitle: "КЛЕИ ДЛЯ ПРОИЗВОДСТВА мягкой мебели и матрасов",
        show: false,
        category: {
            organic: {count: 0, title: "На водній основі", elements: [], ruTitle: "На водной основе",},
            soluble: {count: 0, title: "На основі органічних розчинників", elements: [], ruTitle: "На основе органических растворителей\n",},
            disperse: {count: 0, title: "Клеї в аерозолях", elements: [], ruTitle: "Клеи в аэрозолях",},
        }
    },
    tree: {
        count: 0,
        title: "КЛЕЇ ДЛЯ ДЕРЕВА",
        ruTitle: "КЛЕИ ДЛЯ ДЕРЕВА",
        show: false,
        category: {
            organic: {count: 0, title: "На водній основі", elements: [], ruTitle: "На водной основе",},
            soluble: {count: 0, title: "Поліуретанові", elements: [], ruTitle: "полиуретановые",},
            disperse: {count: 0, title: "Полівинилові", elements: [], ruTitle: "Поливинилови",},
        }
    },
    build: {
        count: 0,
        title: "КЛЕЇ ТА ПРОДУКЦІЯ ДЛЯ БУДІВНИЦТВА",
        ruTitle: "КЛЕИ И ПРОДУКЦИЯ ДЛЯ СТРОИТЕЛЬСТВА",
        show: false,
        category: {
            organic: {ruTitle: "Клеи для паркета и панель", count: 0, title: "Клеї для паркета і панель", elements: []},
            soluble: {ruTitle: "Клеи для вистилювання пола", count: 0, title: "Клеї для вистилювання підлоги", elements: []},
            disperse: {ruTitle: "Клеи и шпаклевки для ремонта паркета", count: 0, title: "Клеї та шпаклівки для ремонту паркета", elements: []},
        }
    },
    automobile: {
        count: 0,
        title: "КЛЕЇ ДЛЯ РЕЗИНИ ТА АВТОМОБІЛЬНОЇ ПРОМИСЛОВОСТІ",
        ruTitle: "КЛЕИ ДЛЯ резину и автомобильной промышленности",
        show: false,
        category: {
            // organic: {count: 0, title: "НА ОСНОВІ ОРГАНІЧНИХ РОЗЧИННИКІВ", elements: []},
            // soluble: {count: 0, title: "РОЗЧИННІ КЛЕЇ", elements: []},
            // disperse: {count: 0, title: "ДИСПЕРСНІ КЛЕЇ", elements: []},
        }
    },
    paper: {
        count: 0,
        title: "КЛЕЇ ДЛЯ ЕТИКЕТОК ТА ПАПЕРУ",
        ruTitle: "КЛЕИ ДЛЯ этикеток И БУМАГИ",
        show: false,
        category: {
        }
    },
    others: {
        title: "ДОПОМІЖНІ ТОВАРИ",
        ruTitle: "вспомогательные ТОВАРЫ",
        show: false,
        count: 0,
        category: {
            building: {count: 0, title: "Розчинники для оббивних клеїв", elements: [], ruTitle: "Растворители для обивочных клеев"},
            furniture: {ruTitle: "Средства для подготовки стяжки", count: 0, title: "Засоби для підготовки стяжки", elements: []},
            automobile: {ruTitle: "Активатори ДЛЯ обивочных клеев", count: 0, title: "АКТИВАТОРИ ДЛЯ ОББИВНИХ КЛЕЇВ", elements: []},
        }
    },
};










const glue = {
    "appointment": "",
    "appointmentRu": "",
    "characters": "",
    "charactersRu": "",
    "description": "",
    "descriptionRu": "",
    "glueSubtype": "",
    "glueType": "shoes",
    "image": null,
    "technicalCharacters": {
        "basic": "",
        "color": "",
        "smell": "",
        "remainder": "",
        "temperature": "",
        "time": "",
        "constancy": "",
        "area": "",
        "suture": "",
        "diameter": "",
        "tempSave": "",
        "productivity": "",
    },
    "title": ""
};
function getValueInObjectByPath(obj, path) {
    if (!path) return obj;
    const properties = path.split('.');
    return getValueInObjectByPath(obj[properties.shift()], properties.join('.'))
}

// console.log(Object.defineProperty(gluesDto, "others.category.organic.title", {get: (e) => {
//         console.log(e);} }), "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");

console.log(getValueInObjectByPath(gluesDto, "others.category.organic"));


export {populateMetadata, dataURItoBlob, randomKey, gluesDto, glue};
