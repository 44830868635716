import React from "react"

const DeleteGlue = ({glues, searchGluesByTitle, searchGlues, showDeleteGlueModal}) => {
    console.log(searchGlues);
    return (
        <div className="main-container">
            <div className="glues-search-topContent">
                <input type="text" onKeyUp={searchGluesByTitle}/>
                <h2>Каталог товарів</h2>
            </div>
            <div className="glues-search-items">
                {
                    searchGlues.length ? searchGlues.map(el => {
                        return (
                            <p key={el.id} className="glues-item" style={{cursor: "pointer"}} onClick={() => {showDeleteGlueModal(el)}}>{el.title}</p>
                        )
                    }) : <p/>
                }
            </div>
        </div>
    )
}

export default DeleteGlue
