import React from "react"

 const GridBackground = () => {
    const renderDiv = (num) => {

    }
    return (
        <div className="grid-background">
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            <div/><div/><div/><div/><div/><div/><div/><div/>
            {/*<div/><div/><div/><div/><div/><div/><div/><div/>*/}
            {/*<div/><div/><div/><div/><div/><div/><div/><div/>*/}
            {/*<div/><div/><div/><div/><div/><div/><div/><div/>*/}
            {/*<div/><div/><div/><div/><div/><div/><div/><div/>*/}
            {/*<div/><div/><div/><div/><div/><div/><div/><div/>*/}
            {/*<div/><div/><div/><div/><div/><div/><div/><div/>*/}
            {/*<div/><div/><div/><div/><div/><div/><div/><div/>*/}
            {/*<div/><div/><div/><div/><div/><div/><div/><div/>*/}
        </div>
    )
}

export default GridBackground
