import app from "firebase/app";

export const sendEmail = (data) => {
    let sendMail = app.functions().httpsCallable('shareMail');
    sendMail(data).then(function(result) {
        console.log('success sending', result);
    }).catch(function(error) {
        console.log('error sending', error);
    });
};

