import { createStore, combineReducers } from 'redux';
import gluesReducer from "./reducer";

const reducers = combineReducers({
    gluesReducer: gluesReducer
});


export default function configureStore() {

    const store = createStore(
        reducers
    );
    return store;
}
