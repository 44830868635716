import app from "firebase/app";

export const doSignInWithEmailAndPassword = (email, password) =>
    app.auth().signInWithEmailAndPassword(email, password).catch(function (error) {
        return error
    });

export const loginWithEmailPassword = async (email, password) =>
    await app.auth().signInWithEmailAndPassword(email, password)

export const registerWithEmailPassword = async (email, password) => {
    try {
        return await app.auth().createUserWithEmailAndPassword(email, password);

    } catch (e) {
        return e
    }

}
