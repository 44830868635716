import React from "react"
import {loginWithEmailPassword, registerWithEmailPassword} from "../../firebase/api/auth";
import SimpleModal from "../../components/Modal";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button/Button";
import {createGlue, deleteGlue, getAllGlues, getGlueByUid, updateGlue} from "../../firebase/api/glues";
import GluesList from "../GluesList";
import ImageCropper from "../../components/ImageCropper";
import {populateMetadata, randomKey, gluesDto, glue} from "../../static/constant";
import {getDownloadUrlFile, uploadFileWithFilePathAndMetadata, deleteFile} from "../../firebase/api/imageUploud";
import {connect} from "react-redux"
import "./admin.css"
import CreateUpdateGlue from "./CreateUpdateGlue";
import DeleteGlue from "./DelateGlue";
import {getAllGluesSuccess} from "../../redux/actions";

const gluesKeys =  process.env.REACT_APP_GLUES_KEYS;


const POST_MEDIA_ROOT_FOLDER = 'post-media/';

class Admin extends React.Component {
    state = {
        activeTab: "create",
        searchGlues: [],
        deleteGlueModal: false,
        glueDto: {...glue},
        updateGlueDto: {...glue},
        email: "",
        password: "",
        image: "",
        title: "",
        description: "",
        prise: "",
        isAdmin: localStorage.getItem('gluesKeys') === gluesKeys,
        currentImage: "",
        glueType: "shoes",
        glueSubtype: "",
        technicalCharacters: {},
        appointment: "",
        characters: "",
        mediaFolder: randomKey(24),
        // createPostDTO: {
        //     media: null,
        //     mediaFolder:
        // }
    };
    componentDidMount() {

        !this.props.glues.length &&
        getAllGlues().then(res => {
            const gluesTree = {...gluesDto};
            console.log("get data");

            res.forEach(el => {
                gluesTree[el.glueType].category[el.glueSubtype].elements.push(el);
                gluesTree[el.glueType].count += 1;
                gluesTree[el.glueType].category[el.glueSubtype].count += 1
            });
            this.props.getAllGluesSuccess(res, "shoes", gluesTree)
        })
    }

    loginUser = (e) => {
        const {email, password} = this.state;
        e.preventDefault();
        loginWithEmailPassword(email, password).then(res => {
            if (res && res.user) {
                localStorage.setItem('gluesKeys', gluesKeys);
                this.setState({isAdmin: true})
            }
        }).catch( e => {
            alert(e.message)
            // console.log(e);
        })
    };
    handleChangeInput = (name) => (e) => {
        const {glueDto, activeTab, updateGlueDto} = this.state;

        activeTab === "create"
            ? this.setState({glueDto: {...glueDto, [name]: e.target.value}})
            : this.setState({updateGlueDto: {...updateGlueDto, [name]: e.target.value}})
    };
    validateGlueDto = (glueDto) => {
        let isValid = true;
        Object.keys(glueDto).map(key => {
            if (!glueDto[key])  {
                isValid =  false
            }
        });
        Object.keys(glueDto.technicalCharacters).map(key => {
            if (!glueDto.technicalCharacters[key])  {
                isValid =  false
            }
        });
        return isValid
    }
    createGlues = (e) => {
        e.preventDefault();
        const {glueDto} = this.state;

        this.validateGlueDto(glueDto) ? createGlue(glueDto).then(res => {
            res && alert(`Клей ${glueDto.title} створено`);
            this.setState({glueDto: {...glue}})
        }).catch(err => {
            err && alert(`Клей ${glueDto.title} не створеноб, винекли певні проблеми`);
        }) : alert("Заповніть всі поля !!!!")

    };
    handleChangeTechnicalCharacters = (title) => (e) => {
        const {glueDto, activeTab, updateGlueDto} = this.state;
        activeTab === "create"
            ? this.setState({glueDto: {...glueDto, technicalCharacters: {...glueDto.technicalCharacters, [title]: e.target.value}}})
            : this.setState({updateGlueDto: {...updateGlueDto, technicalCharacters: {...updateGlueDto.technicalCharacters, [title]: e.target.value}}})
    };

    onDrop = picture => {
        const media = picture.length < 2 ? picture[0] : picture[picture.length - 1];
        this.setState({currentImage: URL.createObjectURL(media), cropImage: true});
    };

    handleCloseModal = () => {
        this.setState({currentImage: ""});
    };

    saveImage = blob => {
        const {mediaFolder, glueDto, activeTab, updateGlueDto} = this.state;
        this.setState({loading: true});
        const media = blob;

        const metadata = populateMetadata(blob);
        const mediaPath = POST_MEDIA_ROOT_FOLDER + mediaFolder + '/' + randomKey(8) + '.png';
        this.handleCloseModal();
        uploadFileWithFilePathAndMetadata(mediaPath, media, metadata).then(ref => {
            getDownloadUrlFile(ref.ref).then(url => {
                const mediaInfo = {
                    downloadMediaUrl: url,
                    mediaPath: mediaPath,
                };
                activeTab === "create"
                    ? this.setState({glueDto: {...glueDto, image: mediaInfo}, loading: false})
                    : this.setState({updateGlueDto: {...updateGlueDto, image: mediaInfo}, loading: false});
            })
        })
    };

    searchGluesByTitle = (e) => {
        const {glues} = this.props;
        const searchField = e.target.value;
        if (e.key === 'Enter' && searchField) {
            const searchGlues = glues.filter(el => {
                return el.title.toLowerCase().includes(searchField.toLowerCase())
            });
            this.setState({searchGlues})
        }

    };
    showDeleteGlueModal = (glue) => {
        this.setState({deleteGlueModal: glue})
    };
    deleteGlue = () => {
        const {deleteGlueModal} = this.state;
        deleteGlueModal.image && this.deleteImage(deleteGlueModal.image.mediaPath);
        deleteGlue(deleteGlueModal.id).then(res => {
            res && alert(`Клей ${deleteGlueModal.title} видалено`);
            this.setState({deleteGlueModal: false})
        }).catch(err => {
            err && alert(`Клей ${deleteGlueModal.title} не видалено винекли певні проблеми`)})

    };

    updateGlue = () => {
        const {updateGlueDto} = this.state;
        console.log(updateGlueDto);
        this.validateGlueDto(updateGlueDto) ?
        updateGlue(updateGlueDto).then(res => {
            res && alert(`Клей ${updateGlueDto.title} оновлено`)
        }).catch(err => {
            err && alert(`Клей ${updateGlueDto.title} не оновлено винекли певні проблеми`)
        }) : alert("Заповніть всі поля !!!!")
    };

    deleteImage = (ref) => {
        const {glueDto, activeTab, updateGlueDto} = this.state;
        deleteFile(ref).then(res => console.log(res));
        if (activeTab === "create") this.setState({glueDto: {...glueDto, image: null}})
        if (activeTab === "update") this.setState({updateGlueDto: {...updateGlueDto, image: null}}, () => {this.updateGlue()})
    };

    chooseEditGlue = glue => {
        this.setState({updateGlueDto: glue})
    };
    logout = () => {
        localStorage.removeItem('gluesKeys')
        this.setState({isAdmin: false, email: "", password: ""})
    };
    render () {
        const {email, password, isAdmin, currentImage,updateGlueDto,

             activeTab, searchGlues, deleteGlueModal, glueDto, } = this.state;

        const {appointmentRu,
            charactersRu,
            descriptionRu,
            glueSubtypeRu, technicalCharacters, title, description, glueType, glueSubtype, appointment, characters, image} = activeTab === "create" ? glueDto : updateGlueDto;

        const {glues} = this.props;
        return (
            <div>

                {!isAdmin ?

                    <SimpleModal open={true}>
                        <form onSubmit={this.loginUser} style={{display: "flex",
                            flexDirection: "column", background: "#fff", maxWidth: "400px", padding: "30px", margin: "0 auto"}}>
                            <TextField
                                id="standard-name"
                                label="Email"
                                // className={classes.textField}
                                value={email}
                                onChange={(e) => {this.setState({email: e.target.value})}}
                                margin="normal"
                            />

                            <TextField
                                id="standard-name"
                                label="Password"
                                // className={classes.textField}
                                value={password}
                                onChange={(e) => {this.setState({password: e.target.value})}}
                                margin="normal"
                            />
                            {/*<input type="text" value={email} onChange={this.handleChangeInput("email")}/>*/}
                            {/*<input type="password" value={password} onChange={this.handleChangeInput("password")}/>*/}
                            <Button type="submit"> Sign in</Button>
                        </form>

                    </SimpleModal>
                    :
                <div className="create-glue">
                    <Button onClick={this.logout} color="secondary" variant="contained" style={{margin: "0 auto"}}>Вийти</Button>
                    <div className="admin-naw">
                        <p  onClick={()=> this.setState({activeTab: "create"})}
                            style={{background: activeTab === "create" ? "#f0d64b" : "#8b8b8b"}}>
                            Додати
                        </p>
                        <p  onClick={()=> this.setState({activeTab: "update"})}
                            style={{background: activeTab === "update" ? "#f0d64b" : "#8b8b8b"}}>
                            Редагувати
                        </p>
                        <p  onClick={()=> this.setState({activeTab: "delete"})}
                            style={{background: activeTab === "delete" ? "#f0d64b" : "#8b8b8b"}}>
                            Видалити
                        </p>
                    </div>
                    {activeTab === "create" || activeTab === "update" ?
                        <CreateUpdateGlue
                            email={email}
                            password={password}
                            // isAdmintitle={isAdmintitle}
                            image={image}
                            description={description}
                            currentImage={currentImage}
                            glueSubtype={glueSubtype}
                            glueType={glueType}
                            image={image}
                            technicalCharacters={technicalCharacters}
                            appointment={appointment}
                            characters={characters}
                            title={title}
                            appointmentRu={appointmentRu}
                            charactersRu={charactersRu}
                            descriptionRu={descriptionRu}
                            glueSubtypeRu={glueSubtypeRu}
                            activeTab={activeTab}
                            updateGlueDto={updateGlueDto}
                            chooseEditGlue={this.chooseEditGlue}

                            deleteImage={this.deleteImage}
                            handleCloseModal={this.handleCloseModal}
                            saveImage={this.saveImage}
                            onDrop={this.onDrop}
                            updateGlue={this.updateGlue}
                            handleChangeInput={this.handleChangeInput}
                            handleChangeTechnicalCharacters={this.handleChangeTechnicalCharacters}
                            createGlues={this.createGlues}
                            searchGlues={searchGlues}
                            searchGluesByTitle={this.searchGluesByTitle}
                        /> :
                        <DeleteGlue
                            glues={glues}
                            searchGlues={searchGlues}
                            searchGluesByTitle={this.searchGluesByTitle}
                            showDeleteGlueModal={this.showDeleteGlueModal}
                        />

                    }
                    {deleteGlueModal &&
                        <SimpleModal open={deleteGlueModal} toggle={() => this.setState({deleteGlueModal: false})}>
                            <div className="delete-glue-modal">
                                <h2>Ви дійсно хочете видалити клей: {deleteGlueModal.title} ?</h2>
                                <div>
                                    <Button onClick={() => this.setState({deleteGlueModal: false})}>Назад</Button>
                                    <Button onClick={this.deleteGlue}>Видалити</Button>
                                </div>
                            </div>

                        </SimpleModal>
                    }
                </div>
                }
            </div>
        )
    }
}
const mapStateToProps = ({gluesReducer}) => {

    const {glues, gluesTree, searchGlues} = gluesReducer;
    // console.log(glues);
    // const {userCampaigns, loading} = dashboard;
    // const {user} = auth;
    return {glues, gluesTree, searchGlues};
};
export default connect(mapStateToProps,{getAllGluesSuccess})(Admin)
