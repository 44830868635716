import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import withStyles from "@material-ui/core/styles/withStyles";


const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 400,
        left: "50%",
        top: "50%",
        // transform: translate(-50%, -50%),
        outline: 'none',
    },
}));



 class SimpleModal extends React.Component{
    state = {
        open: true
    };

    handleOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };
    render () {
        const {classes, toggle, open} = this.props;
        return (
            <div>
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={open}
                    onClose={toggle}
                >
                    <div style={{top: `50%`,
                        left: `50%`,
                        transform: `translate(-50%, -50%)`,
                        position: 'absolute',
                        width: "100%",
                        maxWidth: "1000px",
                        // minHeight: "300px",
                        // backgroundColor: "#ffffff",
                        // padding: "20px",

                        // boxShadow: theme.shadows[5],
                        // padding: theme.spacing(4),
                        // boxSizing: "border-box",
                        outline: 'none',
                        textAlign: "center"}}>
                        {this.props.children}
                    </div>
                </Modal>
            </div>
        );

    }

}
// Click to get the full Modal experience!
//
//     Open Modal
// Performance
// The content of the modal is lazily mounted into the DOM. It ensures that having many closed modals in your React tree won't slow down your page.
//
// However, creating React elements has a cost too. Consider the following case:

{/*<Modal open={false}>*/}
    {/*<Table>*/}
        {/*<TableHead>*/}
            {/*<TableRow>*/}
                {/*<TableCell>Dessert (100g serving)</TableCell>*/}
                {/*<TableCell align="right">Calories</TableCell>*/}
                {/*<TableCell align="right">Fat&nbsp;(g)</TableCell>*/}
            {/*</TableRow>*/}
        {/*</TableHead>*/}
        {/*<TableBody>*/}
            {/*{rows.map(row => (*/}
                {/*<TableRow key={row.id}>*/}
                    {/*<TableCell component="th" scope="row">*/}
                        {/*{row.name}*/}
                    {/*</TableCell>*/}
                    {/*<TableCell align="right">{row.calories}</TableCell>*/}
                    {/*<TableCell align="right">{row.fat}</TableCell>*/}
                {/*</TableRow>*/}
            {/*))}*/}
        {/*</TableBody>*/}
    {/*</Table>*/}
{/*</Modal>*/}


const styles = theme => ({
    paper: {
        position: 'absolute',
        width: "fit-content",
        // backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        // boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 4, 4),
        // outline: 'none',
    },
});

export default withStyles(styles)(SimpleModal);
