import React from 'react';
import './App.css';
import Firebase, {FirebaseContext} from "../../firebase/index";

import {Router} from 'react-router-dom';
import { Route, Switch, Redirect } from 'react-router-dom';

import Admin from "../../pages/Admin";
import Landing from "../../pages/Landing";
import GluesList from "../../pages/GluesList";
import Glue from "../../pages/Glue";
import { createBrowserHistory } from 'history';

class App extends React.Component{
  render() {
      return (
          <div className="App">
              <FirebaseContext.Provider value={new Firebase()}>
                  <Router history={createBrowserHistory()}>
                      <Switch>
                          <Route exact path="/" component={Landing}/>
                          <Route exact path="/admin" component={Admin}/>
                          <Route exact path="/glues-list/:type" component={GluesList}/>
                          <Route exact path="/glue/:id" component={Glue}/>
                      </Switch>
                  </Router>
              </FirebaseContext.Provider>
          </div>
      );
  }

}

export default App;
