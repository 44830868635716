import React from 'react';
import {getAllGlues, getGluesByTitle} from "../../firebase/api/glues";
import {Link, withRouter} from 'react-router-dom'
import {gluesDto} from "../../static/constant";
import {connect} from "react-redux"

import "./glues-list.css"
import {Link as ScrolLink} from "react-scroll/modules";
import {changeLanguage, getAllGluesSuccess, searchGluesByTitle, UpdateGluesTree} from "../../redux/actions";
import GridBackground from "../../components/GridBackground";
import TemporaryDrawer from "../../components/Drawer";


 class GluesList extends React.Component{
    state = {
        glues: this.props.gluesTree,
        loading: true,
        glueType: "",
        searchGlues: ""

    };

    componentDidMount () {
        const { match: { params }, glues, gluesTree, UpdateGluesTree} = this.props;
        const gluesCategory = params.type;
        if (!glues.length)  {
            getAllGlues().then(res => {
                const gluesTree = {...gluesDto};

                gluesCategory === "all"
                    ? gluesTree["shoes"].show = true
                    : gluesTree[gluesCategory].show = true;

                res.forEach(el => {
                    gluesTree[el.glueType].category[el.glueSubtype].elements.push(el);
                    gluesTree[el.glueType].count += 1;
                    gluesTree[el.glueType].category[el.glueSubtype].count += 1;
                });
                this.props.getAllGluesSuccess(res, gluesCategory, gluesTree)
            })
        } else {
            const newCategoryOpen = {...gluesTree};
            if (gluesCategory !== "all") newCategoryOpen[gluesCategory].show = true;
            UpdateGluesTree(newCategoryOpen)
        }
    }

     handleChange = (id, name, value) => {
         let changedGlues = this.state.glues.map(el => {
                 return el.id === id ? {...el, [name]: value} : el
         });
         this.setState({glues: changedGlues})
     };
     showCategory = (category, subcategory = null) => {
         const {gluesTree} = this.props;
         const newData = {...gluesTree};

         typeof subcategory === "string" ?
             newData[category].category[subcategory].show = !gluesTree[category].category[subcategory].show
             : newData[category].show = !gluesTree[category].show;
         this.props.UpdateGluesTree(newData)
     };

     renderGlueLink =(glue) => {
         return(
             <Link
                 className="glues-item"
                 key={glue.id}
                 to={{
                     pathname: `/glue/${glue.id}`,
                     state: glue}}
             >
                 {glue.title}
             </Link>
         )
     };

     renderGluesCategory = () => {
         const {gluesTree} = this.props;
         return Object.keys(gluesTree).map(category => {
             return (
                 <div>
                     <h2 className="glues-category" onClick={this.showCategory.bind(this, category)}>
                         <span>
                             {this.props.isUkraine ? gluesTree[category].title : gluesTree[category].ruTitle}
                         </span>
                         <span className="glues-count">
                             {gluesTree[category].count}
                         </span>
                     </h2>
                     <div>
                         {gluesTree[category].show && this.renderGluesContent(gluesTree[category], category)}
                     </div>
                 </div>
             )
         })
     };

    renderGluesContent = (gluesData, glueType = null) => {
        return Object.keys(gluesData.category).map((type, index) => {
                return (
                    <div key={index + glueType} >
                        <h3 onClick={this.showCategory.bind(this, glueType, type)} className="glues-subcategory">
                            <span>
                                {this.props.isUkraine ? gluesData.category[type].title : gluesData.category[type].ruTitle}
                            </span>
                            <span className="glues-count">
                             {gluesData.category[type].count}
                            </span>
                        </h3>

                            { Object.keys(gluesData.category[type].elements).length && gluesData.category[type].show  ?
                                <div className="glues-item-wrapper">
                                    {
                                        Object.keys(gluesData.category[type].elements)
                                            .map(el => this.renderGlueLink(gluesData.category[type].elements[el]))
                                    }
                                </div>
                                : !gluesData.category[type].show ? <p/> : <p className="glues-item">do not have this glues</p>
                            }

                    </div>

                )
            })
     };
     searchGluesByTitle = (e) => {
         const {glues, searchGluesByTitle} = this.props;
         const searchField = e.target.value;
         if (e.key === 'Enter' && searchField) {
             const searchGlues = glues.filter(el => {
                 return el.title.toLowerCase().includes(searchField.toLowerCase())
             });
             searchGluesByTitle(searchGlues)
         }

     };
     changeLanguage = () => {
         const {isUkraine, changeLanguage} = this.props;
         isUkraine ? localStorage.setItem('ru', "true") : localStorage.removeItem('ru');
         changeLanguage()
     };
    render() {
        const { } = this.state;
        const {gluesTree, searchGlues, isUkraine, changeLanguage} = this.props;
        console.log(gluesTree);
        // console.log(searchGlues);
        // console.log(glues, glueType);
        return (
            <div style={{position: "relative", overflow: "hidden"}}>
                <GridBackground/>
                <div className="gradient-wrapper">
                    <header className="header">
                        <div className="header-wrapper">
                            <span className="fixed-logo">EUROPREN</span>
                            <div className="header-log">
                                <img src="/images/logo-min.svg" onClick={() => this.props.history.push("/")} style={{cursor: "pointer"}}/>
                                <TemporaryDrawer isUkraine={isUkraine} page={"list"}/>
                            </div>
                            <nav className="naw">
                                <Link to={{
                                    pathname: `/`,
                                    state: 'aboutUs'}}>
                                    {isUkraine ? "Про нас" : "О нас"}
                                </Link>
                                <Link to={{
                                    pathname: `/`,
                                    state: 'advantages'}} >
                                    {isUkraine ? "Наші переваги" : "Наши преимущества"}
                                </Link>
                                <Link to="/glues-list/all">
                                    {isUkraine ? "Товари і продукція" : "Товары и продукция"}
                                </Link>
                                <Link to={{
                                    pathname: `/`,
                                    state: 'contacts'}}>
                                    {isUkraine ? "Контакти" : "Контакты"}
                                </Link>
                            </nav>
                            <div className="header-info">
                                <button onClick={this.changeLanguage}>{isUkraine ? "UA" : "RU"}</button>
                                <div onClick={() => this.setState({showPhone: !this.state.showPhone})}>
                                    <span>+380676746215 <br/><span className="phone-type">директор</span></span>
                                    <button className="show-phones"/>
                                </div>

                                {this.state.showPhone &&
                                <p className="header-info-wrapper">
                                    <span>+380674927834 <br/><span className="phone-type">менеджер</span></span>
                                    <span>+380631388408 <br/><span className="phone-type">менеджер</span></span>
                                </p>
                                }
                            </div>
                        </div>
                    </header>

                    <div className="main-container">
                        <div className="glues-search">
                            <div className="glues-search-topContent">
                                 <input type="text" onKeyUp={this.searchGluesByTitle}/>
                                <h2>
                                    {isUkraine ? "Каталог товарів" : "Каталог товаров"}
                                </h2>
                            </div>
                            <div className="glues-search-items">
                                {searchGlues && searchGlues.map(el => {
                                    return this.renderGlueLink(el)
                                })}
                                {searchGlues && !searchGlues.length ? "У нас нема клею із цією назвою" : ""}
                            </div>
                        </div>

                        <div className="glues-list">
                            {this.renderGluesCategory()}
                        </div>
                    </div>
                </div>
                <section className="footer-main">
                    <footer className="footer">
                        <nav className="naw">
                            <Link to={{
                                pathname: `/`,
                                state: 'aboutUs'}}>
                                {isUkraine ? "Про нас" : "О нас"}
                            </Link>
                            <Link to={{
                                pathname: `/`,
                                state: 'advantages'}} >
                                {isUkraine ? "Наші переваги" : "Наши преимущества"}
                            </Link>
                            <Link to="/glues-list/all">
                                {isUkraine ? "Товари і продукція" : "Товары и продукция"}
                            </Link>
                            <Link to={{
                                pathname: `/`,
                                state: 'contacts'}}>
                                {isUkraine ? "Контакти" : "Контакты"}
                            </Link>
                        </nav>
                        <div className="header-info">
                            <button onClick={this.changeLanguage}>{isUkraine ? "UA" : "RU"}</button>
                            <div>
                                <span>+380676746215 <br/>
                                    {isUkraine ? "директор" : "директор"}
                                </span>
                            </div>
                        </div>
                    </footer>

                </section>


            </div>
        )
    }
}


const mapStateToProps = ({gluesReducer}) => {
    const {glues, gluesTree, searchGlues, isUkraine} = gluesReducer;
    return {glues, gluesTree, searchGlues, isUkraine};
};
export default connect(mapStateToProps, {getAllGluesSuccess, UpdateGluesTree, searchGluesByTitle, changeLanguage})(withRouter(GluesList))
