import React, {Fragment} from "react"
import {Uploader} from "../../components/ImageUplouder";
import TextField from "@material-ui/core/TextField/TextField";
import Select from "@material-ui/core/Select/Select";
import {gluesDto} from "../../static/constant";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Button from "@material-ui/core/Button/Button";
import SimpleModal from "../../components/Modal";
import ImageCropper from "../../components/ImageCropper";

const CreateUpdateGlue = ({image,
                              appointmentRu,
                              charactersRu,
                              descriptionRu,
                              description,
    title,
                              currentImage,
                              searchGlues,
                              glueSubtype,
                              glueType,
                              image: imageDto,
                              technicalCharacters,
                              appointment,
                              characters,
                              handleCloseModal,
                              saveImage,
                              activeTab,
                              onDrop,
                              updateGlue,
                              searchGluesByTitle,
                              handleChangeInput,
                              handleChangeTechnicalCharacters,
                              chooseEditGlue,
                              updateGlueDto,
                              deleteImage,
                              createGlues}) => {
    const renderDiv = (num) => {

    }
    return (
        <div className="create-glue">

            {   activeTab === "update" &&
                <div className="main-container">
                    <div className="glues-search-topContent">
                        <input type="text" onKeyUp={searchGluesByTitle}/>
                        <h2>Каталог товарів</h2>
                    </div>
                    <div className="glues-search-items">
                        {
                            searchGlues.length ? searchGlues.map(el => {
                                return (
                                    <p onClick={() => chooseEditGlue(el)} className="glues-item" style={{cursor: "pointer"}} key={el.id}>{el.title}</p>
                                )
                            }) : <p/>
                        }
                    </div>
                </div>
            }
            {(activeTab === "update" && updateGlueDto.id) || activeTab === "create" ?
           <Fragment>
            <div className="create-glue-wrapper">
                <div className="create-glue-image">
                    {!image ?
                        <Uploader
                            buttonText='Upload photo'
                            onDrop={onDrop}
                            style={{background: 'red'}}
                        /> :
                        <div>
                            <img src={image.downloadMediaUrl} alt=""/>
                            <Button onClick={() => deleteImage(image.mediaPath)}>Видалити картинку</Button>
                        </div>

                    }
                </div>
                <div className="create-glue-main-info">
                    <TextField
                        id="standard-name"
                        label="Title"
                        // className={classes.textField}
                        required
                        value={title}
                        onChange={handleChangeInput("title")}
                        margin="normal"
                        className="create-form-input"
                    />
                    <TextField
                        id="standard-name"
                        label="Description"
                        // className={classes.textField}
                        value={description}
                        onChange={handleChangeInput("description")}
                        margin="normal"
                        className="create-form-input"
                    />
                    <TextField
                        id="standard-name"
                        label="Description RU"
                        // className={classes.textField}
                        value={descriptionRu}
                        onChange={handleChangeInput("descriptionRu")}
                        margin="normal"
                        className="create-form-input"
                    />
                    {/*<FormControl>*/}
                    {/*<InputLabel htmlFor="glueType">Type</InputLabel>*/}
                    <Select
                        value={glueType}
                        onChange={handleChangeInput("glueType")}
                        placeholder="Category"
                        inputProps={{
                            name: 'glueType',
                            // id: 'glueType',
                        }}
                    >
                        {Object.keys(gluesDto).map(type => {
                            return (
                                <MenuItem value={type}>{gluesDto[type].title}</MenuItem>
                            )
                        })}
                    </Select>
                    { glueType && <Select
                        value={glueSubtype}
                        onChange={handleChangeInput("glueSubtype")}
                        inputProps={{
                            name: 'glueSubtype',
                            // id: 'glueSubtype',
                        }}
                    >
                        {Object.keys(gluesDto[glueType].category).map(type => {
                            return (
                                <MenuItem value={type}>{gluesDto[glueType].category[type].title}</MenuItem>
                            )
                        })}
                    </Select>}
                    {/*</FormControl>*/}
                </div>
                <div className="technical-characters">
                    <table>
                        <tbody>
                        <tr>
                            <td>Основа</td>
                            <td>
                                <TextField
                                    label="Основа"
                                    value={technicalCharacters.basic}
                                    onChange={handleChangeTechnicalCharacters("basic")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Колір</td>
                            <td>
                                <TextField
                                    label="Колір"
                                    value={technicalCharacters.color}
                                    onChange={handleChangeTechnicalCharacters("color")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Запах</td>
                            <td>
                                <TextField
                                    label="Запах"
                                    value={technicalCharacters.smell}
                                    onChange={handleChangeTechnicalCharacters("smell")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Вміст сухого залишку</td>
                            <td>
                                <TextField
                                    label="Вміст сухого залишку"
                                    value={technicalCharacters.remainder}
                                    onChange={handleChangeTechnicalCharacters("remainder")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Температура роботи</td>
                            <td>
                                <TextField
                                    label="Температура роботи"
                                    value={technicalCharacters.temperature}
                                    onChange={handleChangeTechnicalCharacters("temperature")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Відкритий час</td>
                            <td>
                                <TextField
                                    label="Відкритий час"
                                    value={technicalCharacters.time}
                                    onChange={handleChangeTechnicalCharacters("time")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Вязкозть</td>
                            <td>
                                <TextField
                                    label="Вязкозть"
                                    value={technicalCharacters.constancy}
                                    onChange={handleChangeTechnicalCharacters("constancy")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Нанесення клея</td>
                            <td>
                                <TextField
                                    label="Нанесення клея"
                                    value={technicalCharacters.area}
                                    onChange={handleChangeTechnicalCharacters("area")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Температура виносливості Шва</td>
                            <td>
                                <TextField
                                    label="Тем вин шва"
                                    value={technicalCharacters.suture}
                                    onChange={handleChangeTechnicalCharacters("suture")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Діаметер Сопла</td>
                            <td>
                                <TextField
                                    label="Діаметер Сопла"
                                    value={technicalCharacters.diameter}
                                    onChange={handleChangeTechnicalCharacters("diameter")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Температура зберігання</td>
                            <td>
                                <TextField
                                    label="Температура зберігання"
                                    value={technicalCharacters.tempSave}
                                    onChange={handleChangeTechnicalCharacters("tempSave")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>Продуктивність</td>
                            <td>
                                <TextField
                                    label="Продуктивність"
                                    value={technicalCharacters.productivity}
                                    onChange={handleChangeTechnicalCharacters("productivity")}
                                    margin="normal"
                                    className="technical-characters-input"
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="texts-field-wrapper">
                    <div className="appointment">
                        <TextField
                            label="ПРИЗНАЧЕННЯ"
                            multiline
                            value={appointment}
                            onChange={handleChangeInput('appointment')}
                            margin="normal"
                            variant="outlined"
                            className="text-field"
                        />
                        <TextField
                            label="ПРИЗНАЧЕННЯ RU"
                            multiline
                            value={appointmentRu}
                            onChange={handleChangeInput('appointmentRu')}
                            margin="normal"
                            variant="outlined"
                            className="text-field"
                        />
                    </div>
                    <div className="characters">
                        <TextField
                            id="outlined-multiline-flexible"
                            label="ХАРАКТЕРИСТИКА"
                            multiline
                            value={characters}
                            onChange={handleChangeInput('characters')}
                            margin="normal"
                            variant="outlined"
                            className="text-field"
                        />
                        <TextField
                            id="outlined-multiline-flexible"
                            label="ХАРАКТЕРИСТИКА RU"
                            multiline
                            value={charactersRu}
                            onChange={handleChangeInput('charactersRu')}
                            margin="normal"
                            variant="outlined"
                            className="text-field"
                        />
                    </div>
                </div>
                {activeTab === "create"
                    ?  <Button type="submit" onClick={createGlues} variant="contained" color="primary"  className="button-submit"> Create Glue</Button>
                    : <Button type="submit" onClick={updateGlue} variant="contained" color="primary"  className="button-submit"> Update Glue</Button>
                }
            </div>

           </Fragment>
            : ""
            }

            {   currentImage &&
            <SimpleModal open={currentImage} toggle={handleCloseModal}>
                <div style={{background: "#fff"}}>
                    <ImageCropper onDrop={saveImage}
                                  currentImage={currentImage}/>
                </div>

            </SimpleModal>
            }

        </div>
    )
}

export default CreateUpdateGlue
