
import {Types} from "./actions";
import {gluesDto} from "../static/constant";

const defaultState = {
    glues: [],
    gluesTree: {...gluesDto},
    loading: false,
    activeCategory: "",
    searchGlues: false,
    isUkraine: !localStorage.getItem('ru'),
};

const gluesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case Types.GET_ALL_GLUES_SUCCESS: {
            console.log(action);
            return {...state, ...action.payload}
        }

        case Types.UPDATE_GLUES_TREE: {
            console.log(action.payload)
            return {...state, gluesTree: action.payload}
        }
        case Types.SEARCH_GLUES_BY_TITLE: {
            console.log(action.payload)
            return {...state, searchGlues: action.payload}
        }
        case Types.CHANGE_LANGUAGE: {
            return {...state, isUkraine: !state.isUkraine}
        }

        default:
            return state;
    }
};

export default gluesReducer;
