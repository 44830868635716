import React from 'react';
import ImageUploader from 'react-images-upload';
import './Uploader.css';
const MAX_FILE_SIZE = 5242880;//Bytes
const ALLOWED_IMAGE_EXTENTIONS = ['.jpg', '.png', '.gif'];


export const Uploader = ({
                             buttonText,
                             onDrop
                         }) => {
    return (
        <ImageUploader
            className="Uploader d-flex justify-content-center align-items-center img-thumbnail"
            withIcon={false}
            withLabel={false}
            singleImage={true}
            //withPreview
            maxFileSize={MAX_FILE_SIZE}
            imgExtension={ALLOWED_IMAGE_EXTENTIONS}
            fileContainerStyle={{display: 'flex', flexDirection: 'row'}}
            buttonText={"Upload a Photo +"}
            onChange={onDrop}
        />
    );
};
